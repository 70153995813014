import { render, staticRenderFns } from "./krasRiskRegister.vue?vue&type=template&id=968db0ac"
import script from "./krasRiskRegister.vue?vue&type=script&lang=js"
export * from "./krasRiskRegister.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\Project\\KTNL\\KTNLFrontEnd\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('968db0ac')) {
      api.createRecord('968db0ac', component.options)
    } else {
      api.reload('968db0ac', component.options)
    }
    module.hot.accept("./krasRiskRegister.vue?vue&type=template&id=968db0ac", function () {
      api.rerender('968db0ac', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/ram/kras/risk/krasRiskRegister.vue"
export default component.exports